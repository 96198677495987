import React from 'react';
const Googlemaps = () => {
  return (
    <div>
      <iframe
        style={{ width: "100%", height: "500px" }}
        title='romeo'
        src="https://maps.google.com/maps?q=nanocodes%20&t=&z=13&ie=UTF8&iwloc=&output=embed"
      ></iframe>
    </div>
  );
};

export default Googlemaps;
